import API from 'services/api';
import { setAlert } from 'redux/actions/alert';
import { fetchEventPayments } from './payment';

export const TOGGLE_SHOW_PASSWORD = 'TOGGLE_SHOW_PASSWORD';
export const TOGGLE_EDIT_USER_INFO = 'TOGGLE_EDIT_USER_INFO';
export const UPDATE_INFO_FIRST_NAME = 'UPDATE_INFO_FIRST_NAME';
export const UPDATE_INFO_LAST_NAME = 'UPDATE_INFO_LAST_NAME';
export const UPDATE_INFO_EMAIL = 'UPDATE_INFO_EMAIL';
export const UPDATE_INFO_PHONE = 'UPDATE_INFO_PHONE';
export const UPDATE_INFO_ADDRESS_STREET = 'UPDATE_INFO_ADDRESS_STREET';
export const UPDATE_INFO_ADDRESS_POSTCODE = 'UPDATE_INFO_ADDRESS_POSTCODE';
export const UPDATE_INFO_ADDRESS_CITY = 'UPDATE_INFO_ADDRESS_CITY';
export const UPDATE_INFO_PASSWORD = 'UPDATE_INFO_PASSWORD';
export const UPDATE_INFO_BIRTHDAY = 'UPDATE_INFO_BIRTHDAY';
export const UPDATE_INFO_MARKETING = 'UPDATE_INFO_MARKETING';
export const UPDATE_INFO_COUNTRY = 'UPDATE_INFO_COUNTRY';
export const UPDATE_INFO_GENDER = 'UPDATE_INFO_GENDER';
export const UPDATE_INFO_CURRENT_PASSWORD = 'UPDATE_INFO_CURRENT_PASSWORD';

export const TOGGLE_SHOW_CURRENT_PASSWORD = 'TOGGLE_SHOW_CURRENT_PASSWORD';

export const SET_PARTNER_GENDER = 'SET_PARTNER_GENDER';
export const SET_ROOMTYPE = 'SET_ROOMTYPE';
export const UPDATE_ROOMPARTNERS = 'UPDATE_ROOMPARTNERS';

export const SAVE_ROOM_PARTNER_INFO_REQUESTED =
  'SAVE_ROOM_PARTNER_INFO_REQUESTED';
export const SAVE_ROOM_PARTNER_INFO_RESPONSE =
  'SAVE_ROOM_PARTNER_INFO_RESPONSE';
export const SAVE_ROOM_PARTNER_INFO_ERROR = 'SAVE_ROOM_PARTNER_INFO_ERROR';

export const FETCH_USER_INFO_REQUESTED = 'FETCH_USER_INFO_REQUESTED';
export const FETCH_USER_INFO_RESPONSE = 'FETCH_USER_INFO_RESPONSE';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_RESPONSE';

export const SAVE_USER_INFO_REQUESTED = 'SAVE_USER_INFO_REQUESTED';
export const SAVE_USER_INFO_RESPONSE = 'SAVE_USER_INFO_RESPONSE';
export const SAVE_USER_INFO_ERROR = 'SAVE_USER_INFO_ERROR';
export const USER_ADDS_DISCOUNT_CODE_REQUESTED =
  'USER_ADDS_DISCOUNT_CODE_REQUESTED';
export const USER_ADDS_DISCOUNT_CODE_FAIL = 'USER_ADDS_DISCOUNT_CODE_FAIL';
export const USER_ADDS_DISCOUNT_CODE_RESPONSE_OK =
  'USER_ADDS_DISCOUNT_CODE_RESPONSE_OK';

export const FETCH_GROUP_USERS_REQUESTED = 'FETCH_GROUP_USERS_REQUESTED';
export const FETCH_GROUP_USERS_RESPONSE = 'FETCH_GROUP_USERS_RESPONSE';
export const FETCH_GROUP_USERS_ERROR = 'FETCH_GROUP_USERS_ERROR';

export const fetchUserInfo = () => async (dispatch) => {
  dispatch({ type: FETCH_USER_INFO_REQUESTED });
  try {
    const res = await API.get(`/api/users/me`);
    dispatch({ type: FETCH_USER_INFO_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_USER_INFO_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const fetchGroupUsers = (groupId) => async (dispatch) => {
  dispatch({ type: FETCH_GROUP_USERS_REQUESTED });
  try {
    const res = await API.get(`/api/groups/users/${groupId}`);
    dispatch({ type: FETCH_GROUP_USERS_RESPONSE, payload: res.data });
  } catch (error) {
    dispatch({
      type: FETCH_GROUP_USERS_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const postRoomPartnerByGenderConfirmation =
  (roomPartner) => async (dispatch) => {
    dispatch({ type: SAVE_ROOM_PARTNER_INFO_REQUESTED });
    delete roomPartner.byRoomtype;
    delete roomPartner.roompartners;
    try {
      const res = await API.post('/api/users/me/roompartner', roomPartner);
      dispatch({ type: SAVE_ROOM_PARTNER_INFO_RESPONSE, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
      }

      dispatch({
        type: SAVE_ROOM_PARTNER_INFO_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const postRoomPartnerByRoomConfirmation =
  (roomPartner) => async (dispatch) => {
    dispatch({ type: SAVE_ROOM_PARTNER_INFO_REQUESTED });
    delete roomPartner.byGender;
    if (roomPartner.byRoomtype === '2h')
      roomPartner.roompartners = [roomPartner.roompartners[0]];
    try {
      const res = await API.post('/api/users/me/roompartner', roomPartner);
      dispatch(setAlert('Valinta tehty onnistuneesti!', 'success', true));
      dispatch({ type: SAVE_ROOM_PARTNER_INFO_RESPONSE, payload: res.data });
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
      }

      dispatch(fetchUserInfo());

      dispatch({
        type: SAVE_ROOM_PARTNER_INFO_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const saveUserInfo = (info) => async (dispatch) => {
  dispatch({ type: SAVE_USER_INFO_REQUESTED });
  delete info._id;
  try {
    // Remove currentPassword if no new password is set
    if (!info.password) {
      delete info.currentPassword;
    }
    const res = await API.put('/api/users/me', info);
    dispatch({ type: SAVE_USER_INFO_RESPONSE, payload: res.data });
    dispatch(setAlert('Tiedot tallennettiin onnistuneesti', 'success', true));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
    }

    dispatch({
      type: SAVE_USER_INFO_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const toggleEditUserInfo = () => ({
  type: TOGGLE_EDIT_USER_INFO,
});

export const toggleShowPassword = () => ({
  type: TOGGLE_SHOW_PASSWORD,
});

export const toggleShowCurrentPassword = () => ({
  type: TOGGLE_SHOW_CURRENT_PASSWORD,
});

export const updateInfoFirstName = (payload) => ({
  type: UPDATE_INFO_FIRST_NAME,
  payload,
});

export const updateInfoLastName = (payload) => ({
  type: UPDATE_INFO_LAST_NAME,
  payload,
});

export const updateInfoEmail = (payload) => ({
  type: UPDATE_INFO_EMAIL,
  payload,
});

export const updateInfoPhone = (payload) => ({
  type: UPDATE_INFO_PHONE,
  payload,
});

export const updateInfoPassword = (payload) => ({
  type: UPDATE_INFO_PASSWORD,
  payload,
});

export const updateInfoBirthday = (payload) => ({
  type: UPDATE_INFO_BIRTHDAY,
  payload,
});

export const toggleInfoMarketing = () => ({
  type: UPDATE_INFO_MARKETING,
});

export const updateInfoAddressStreet = (payload) => ({
  type: UPDATE_INFO_ADDRESS_STREET,
  payload,
});

export const updateInfoAddressCity = (payload) => ({
  type: UPDATE_INFO_ADDRESS_CITY,
  payload,
});

export const updateInfoAddressPostCode = (payload) => ({
  type: UPDATE_INFO_ADDRESS_POSTCODE,
  payload,
});

export const updateInfoCountry = (payload) => ({
  type: UPDATE_INFO_COUNTRY,
  payload,
});

export const updateInfoGender = (payload) => ({
  type: UPDATE_INFO_GENDER,
  payload,
});

export const updateInfoCurrentPassword = (payload) => ({
  type: UPDATE_INFO_CURRENT_PASSWORD,
  payload,
});

export const setPartnerGender = (payload) => ({
  type: SET_PARTNER_GENDER,
  payload,
});

export const setRoomtype = (payload) => ({
  type: SET_ROOMTYPE,
  payload,
});

export const updateRoompartners = (payload) => ({
  type: UPDATE_ROOMPARTNERS,
  payload,
});

export const userAddsDiscountCode = (payload) => async (dispatch) => {
  dispatch({ type: USER_ADDS_DISCOUNT_CODE_REQUESTED });
  try {
    await API.post('/api/discountCodes', payload);
    dispatch({ type: USER_ADDS_DISCOUNT_CODE_RESPONSE_OK });
    dispatch(setAlert('Alennuskoodi lisätty onnistuneesti!', 'success', true));
    dispatch(fetchEventPayments());
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error', true)));
    }
    dispatch({
      type: USER_ADDS_DISCOUNT_CODE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
