import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordField = ({
  className,
  label,
  value,
  onChange,
  showPassword,
  onTogglePassword,
  required = false,
  name,
  id,
  autoComplete,
  inputProps,
  inputLabelProps,
}) => {
  return (
    <TextField
      className={className}
      margin="dense"
      required={required}
      fullWidth
      name={name}
      label={label}
      type={showPassword ? 'text' : 'password'}
      id={id}
      value={value || ''}
      onChange={onChange}
      autoComplete={autoComplete}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onTogglePassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={inputLabelProps}
    />
  );
};

export default PasswordField;
