import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import EventInformationPackets from 'components/views/customer/event/EventInformationPackets';
import MobileNavigation from 'components/navigation/MobileNavigation';
import UserInformation from 'components/views/customer/userDashboard/UserInformation';
import UserFerry from 'components/views/customer/ferrys/UserFerry';
import UserAccomodation from 'components/views/customer/accomodation/UserAccomodation';

import { fetchUserInfo } from 'redux/actions/customer';
import UserPayments from '../payments/UserPayments';

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  userInfoSectionMobile: {
    marginTop: '3%',
    marginLeft: '3%',
    width: '22%',
    display: 'flex',
    flexDirection: 'column',
  },
  userPaymentSectionMobile: {
    marginTop: '8%',
    width: '91%',
    display: 'flex',
    flexDirection: 'column',
  },
  userFerrySectionMobile: {
    marginTop: '8%',
    width: '91%',
    display: 'flex',
    alignSelf: 'flex-end',
    flexDirection: 'column',
  },
  userAccomodationSectionMobile: {
    marginTop: '8%',
    width: '91%',
    display: 'flex',
    flexDirection: 'column',
  },

  eventInformationSectionMobile: {
    marginTop: '8%',
    marginBottom: '5%',
    width: '91%',
    display: 'flex',
    alignSelf: 'flex-end',
    flexDirection: 'column',
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  userInfoSection: {
    marginTop: '3%',
    marginLeft: '3%',
    width: '22%',
    display: 'flex',
    flexDirection: 'column',
  },
  userFerrySection: {
    marginTop: '8%',
    marginLeft: '4%',
    marginBottom: '8%',
    width: '23%',
    display: 'flex',
    flexDirection: 'column',
  },
  userAccomodationSection: {
    marginTop: '8%',
    marginLeft: '4%',
    width: '23%',
    display: 'flex',
    flexDirection: 'column',
  },
  userPaymentSection: {
    marginTop: '8%',
    marginLeft: '4%',
    width: '18%',
    display: 'flex',
    flexDirection: 'column',
  },
  eventInformationSection: {
    marginTop: '15%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const UserDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  return (
    <Fragment>
      {mobileScreen ? (
        <div className={classes.mobileContainer}>
          <MobileNavigation />
          <UserInformation />
          <div className={classes.userPaymentSectionMobile}>
            <UserPayments id="user-section" />
          </div>
          <div className={classes.userFerrySectionMobile}>
            <UserFerry id="ferry-section" />
          </div>
          <div className={classes.userAccomodationSectionMobile}>
            <UserAccomodation id="accomodation-section" />
          </div>
          <div className={classes.eventInformationSectionMobile}>
            <EventInformationPackets />
          </div>
        </div>
      ) : (
        <Container maxWidth="xl" className={classes.container}>
          <div className={classes.userInfoSection}>
            <UserInformation />
          </div>
          <div className={classes.userFerrySection}>
            <UserFerry />
          </div>
          <div className={classes.userAccomodationSection}>
            <UserAccomodation />
            <div className={classes.eventInformationSection}>
              <EventInformationPackets />
            </div>
          </div>
          <div className={classes.userPaymentSection}>
            <UserPayments />
          </div>
        </Container>
      )}
      <Typography
        style={{
          textAlign: 'center',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        <Link
          style={{
            color: '#FFFFFF',
          }}
          to="/tietosuojaseloste-1.pdf"
          target="_blank"
        >
          Tietosuoja- ja rekisteriseloste
        </Link>
      </Typography>
    </Fragment>
  );
};

export default UserDashboard;
