import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { login } from 'redux/actions/auth';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import CustomerAlert from 'components/layouts/CustomerAlert';
import Container from './Container';
import Header from './Header';
import HeaderInfo from './HeaderInfo';
import SubmitButton from './SubmitButton';

import { CUSTOMER, ADMINROLES } from 'constants/roles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formInput: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontFamily: 'futura-pt, sans-serif;',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '119%',
    /* or 74px */
    color: '#FFFFFF',
    opacity: 0.5,
  },

  inputText: {
    color: '#FFFFFF',
    opacity: 0.95,
  },

  inputLabel: {
    color: '#FFFFFF',
    opacity: 0.95,
    '&.focused': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
    '&.shrink': {
      color: '#FFFFFF',
      opacity: 0.95,
    },
  },

  forgotLink: {
    color: '#FFFFFF',
    opacity: 0.95,
  },
}));

export const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mobileScreen = useMediaQuery('(max-width:990px)');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onClick = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const { isAuthenticated, role } = useSelector((state) => state.auth);

  if (isAuthenticated && ADMINROLES.indexOf(role) !== -1) {
    return <Redirect to="/admin-dashboard"></Redirect>;
  } else if (isAuthenticated && role === CUSTOMER) {
    return <Redirect to="/user-dashboard"></Redirect>;
  }

  return (
    <Fragment>
      <Container mobileScreen={mobileScreen}>
        <Header>Kirjaudu sisään</Header>
        <HeaderInfo>
          Kirjaudu sisään jo luoduilla tunnuksilla. Mikäli olet unohtanut
          salasanasi, voit vaihtaa salasanasi alta ”unohtuiko salasana?”
          kohdasta.
        </HeaderInfo>
        <CustomerAlert />
        <form className={classes.form} noValidate>
          <TextField
            className={classes.formInput}
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label="Sähköposti"
            value={email}
            onChange={(e) => onChange(e)}
            autoComplete="email"
            autoFocus
            InputProps={{
              className: classes.inputText,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: 'focused',
                shrink: 'shrink',
              },
            }}
          />
          <TextField
            className={classes.formInput}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            value={password}
            onChange={(e) => onChange(e)}
            autoComplete="current-password"
            InputProps={{
              className: classes.inputText,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: 'focused',
                shrink: 'shrink',
              },
            }}
          />
          <SubmitButton mobileScreen={mobileScreen} onClick={onClick}>
            Kirjaudu sisään
          </SubmitButton>
          <Typography>
            <Link className={classes.forgotLink} to="/password-reset-request">
              Unohtuiko salasana?
            </Link>
          </Typography>
          <Typography>
            <Link
              className={classes.forgotLink}
              to="/tietosuojaseloste-1.pdf"
              target="_blank"
            >
              Tietosuoja- ja rekisteriseloste
            </Link>
          </Typography>
        </form>
      </Container>
    </Fragment>
  );
};

export default Login;
